@import '~antd/dist/antd.less';

body {
  margin: 0;
  color: rgba(0, 0, 0, 0.65);
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

p,
table,
li {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

h1 {
  margin-top: 30px;
  font-size: 1.8rem;
  font-weight: 500;
}

.logo {
  padding: 1.7rem 0;
}
.main-content {
  height: calc(100vh - 105px);
  margin-top: 105px;
  min-width: 400px;
  transition: all 0.2s;
  overflow-y: auto;
  &.ant-layout-content {
    padding-bottom: 80px;
  }
}
.side-menu {
  height: calc(100vh - 105px);
  position: fixed;
  overflow-y: auto;
  top: 105px;
}
.header-nav {
  position: fixed;
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.home-nav-card {
  min-height: 150px;
  margin-bottom: 20px;
}
.ant-layout-header {
  min-height: 6.5rem;
  background: #fff;
  border-bottom: 0.25rem solid @main-brand;
  font-size: x-large;
  color: #8a8a8a;
}
.ant-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
}
.ant-layout-sider-zero-width-trigger {
  top: 0;
}
.ant-alert {
  margin-top: 30px;
  margin-bottom: 30px;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
}
.ant-alert-description {
  font-size: 0.95rem;
}
.markdown-doc {
  h1,
  h2,
  h3 {
    margin-top: 30px;
    margin-bottom: 15px;
  }
}
.trimmed {
  overflow-y: auto;
  height: 100vh;
}
table {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 30px;
  th {
    background: #fafafa;
  }
  th,
  td {
    padding: 16px 16px;
    overflow-wrap: break-word;
    border: 1px solid #eee;
  }
}

@main-brand: #39b54a;@primary-color: @main-brand;@info-color: @blue-6;@menu-highlight-color: @main-brand;